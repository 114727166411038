import { defineStore } from 'pinia';
import {ArchiveDTO} from '~/dto/ArchiveDTO';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

export const archiveStore = defineStore('archiveStore', {
  state: () => ({
    archives: [] as ArchiveDTO[],
    archiveDefault: new ArchiveDTO(),
  }),
  actions: {
    setArchives(data: any) {
      this.archives = data as ArchiveDTO[];
      this.archiveDefault = data.find((archive:ArchiveDTO) => archive.isArchiveId0 === true);
    },
    setArchiveDefault(archiveId: number) {
      this.archiveDefault  = this.archives.find((archive:ArchiveDTO) => archive.id === archiveId);
    }
  },
  persist: true,
});

  
